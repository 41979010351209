<template>
  <div id="vm-cola-payment" class="vm-cola-payment">
    <div class="top-container">
      <h1>Payment</h1>
      <div class="user-status">
        <div class="name">
          Logged in as
          <span v-if="isLogin">{{ user.name }}</span>
          <span v-else>Guest</span>
        </div>
        <button @click.prevent="cancelOrder(true, '/vmcola/products')">
          {{ isLogin ? 'LOG OUT' : 'CANCEL ORDER' }}
        </button>
      </div>
    </div>
    <div class="payment-container">
      <div class="payment-icon">
        <img src="/static/img/vending-machine/icon-qris.svg" alt="QRIS | Sociolla" />
        <img src="/static/img/vending-machine/icon-gpn.svg" alt="GPN | Sociolla" />
      </div>
      <h2>SOCIOLLA</h2>
      <h4>NMID : {{ reference_code }}</h4>
      <h4 class="price">
        TOTAL PRICE <span>{{ total_price | currencyRp }}</span>
      </h4>
      <div class="qrcode">
        <img v-if="qrcode" :src="qrcode" alt="QRCode Payment Sociolla Vending Machine" />
        <span v-else>Waiting for QRCode, Please wait...</span>
      </div>
      <div class="payment-refresh">
        <div v-if="is_loading_fetch_detail">
          <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
        </div>
        <template v-else>
          <div class="label">
            Tekan tombol di bawah ini jika layar belum <br />
            berubah saat pembayaran telah berhasil.
          </div>
          <button @click.prevent="onClickRefreshPaymentStatus">Verify Payment</button>
        </template>
      </div>
      <h5>CARA MELAKUKAN PEMBAYARAN DENGAN QRIS</h5>
      <ul class="list-pembayaran">
        <li>
          <img src="/static/img/vending-machine/pembayaran-1-new.svg" alt="Pembayaran 1 | Sociolla" />
          <div class="caption">Buka aplikasi pembayaran digital dengan scan QR Code di atas.</div>
        </li>
        <li>
          <img src="/static/img/vending-machine/pembayaran-2-new.svg" alt="Pembayaran 2 | Sociolla" />
          <div class="caption">Pastikan nama merchant yang muncul adalah a/n Sociolla.</div>
        </li>
        <li>
          <img src="/static/img/vending-machine/pembayaran-3-new.svg" alt="Pembayaran 3 | Sociolla" />
          <div class="caption">Masukkan nominal pembayaran sesuai dengan total belanja kamu.</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import SocketIO from 'socket.io-client';
import autotimeout from '@/mixins/auto-timeout';
const Config = require('~/config/default.env').default;
let socket;

export default {
  name: 'vmColaPayment',
  mixins: [helpers, autotimeout],
  beforeRouteLeave(to, from, next) {
    this.resetData();
    this.cancelOrder();
    next();
  },
  data() {
    return {
      order_detail: '',
      callback_counting: 0,
      callback_detail: '',
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    total_price() {
      return this.$route.query.total;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    qrcode() {
      if (this.callback_detail) {
        if (
          this.callback_detail.payment_response &&
          this.callback_detail.payment_response.actions &&
          this.callback_detail.payment_response.actions.length
        ) {
          return this.callback_detail.payment_response.actions[0].url;
        } else if (this.callback_detail.payment_response && this.callback_detail.payment_response.final_payment_code) {
          return this.callback_detail.payment_response.final_payment_code;
        } else if (this.callback_detail.payment_code) {
          return this.callback_detail.payment_code;
        } else if (this.callback_detail.actions) {
          return this.callback_detail.actions[0].url;
        }
      }
    },
    reference_code() {
      return this.$route.query.reference_code;
    },
  },
  created() {
    this.resetData();
    this.initSocket();
  },
  mounted() {
    if (this.$route.name == 'vmColaPayment') {
      this.getCallback();
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_GLOBAL_LOADING', false);
  },
  methods: {
    async onClickRefreshPaymentStatus() {
      this.getOrderDetail(true);
    },
    async getCallback() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.$route.query.id_order;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        await axios
          .post(
            `${Config.MS_PAYMENT_API_URL}/payments/callback`,
            {
              id_order: id_order,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            this.callback_detail = res.data.data;
            this.getOrderDetail();
          })
          .catch((err) => {
            console.log(err);
            this.getOrderDetail();
          });
      }
    },
    async getOrderDetail(isManual = false) {
      this.callback_counting++;
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.$route.query.id_order;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        await axios
          .get(`${Config.MS_ORDER_API_URL}/order/${id_order}`, {
            headers: headers,
          })
          .then((res) => {
            const res_order = res.data.data;
            this.order_detail = res_order;
            if (
              res_order.soco_state != 'Awaiting Veritrans payment' &&
              res_order.soco_state != 'Waiting payment' &&
              (res_order.soco_state == 'Delivered' || res_order.soco_state == 'Payment accepted')
            ) {
              this.redirectPayment(res_order.reference_code);
            } else if (res_order.soco_state == 'Cancelled' || res_order.soco_state == 'Payment Failure') {
              this.$router.push({ path: '/vmcola/order/payment-failed' });
              socket?.off('payment_status_update');
            } else if (res_order.soco_state.toLowerCase() == 'waiting payment' && isManual) {
              this.$toasted.global.show('Pembayaran sedang diproses');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    redirectPayment(reference = '') {
      const reference_code =
        this.order_detail && this.order_detail.reference_code
          ? this.order_detail.reference_code
          : this.callback_detail && this.callback_detail.reference_code
          ? this.callback_detail.reference_code
          : '';
      if (reference && reference_code ? reference_code == reference : true) {
        const host = window.location && window.location.hostname;
        const is_local = host ? host.indexOf('localhost') > -1 || host.indexOf('uat') > -1 : false;
        this.$router.push({
          path: '/vmcola/order/payment-success',
          query: {
            total: this.$route.query.total,
            reference_code: this.$route.query.reference_code,
            payment_method: 'GOPAY',
            'disable-bucket': is_local,
          },
        });
      }
    },
    resetData() {
      this.order_detail = '';
      this.callback_counting = 0;
      this.callback_detail = '';
    },
    initSocket() {
      socket = SocketIO(`${Config.MS_SOCKET_API_URL}/notifications`, { transports: ['websocket'] });
      // socket.on('logs', console.info)
      socket.on('connect', () => {
        const room = (this.user && this.user._id) || this.$route.query.id_order;
        socket.emit('join', room);
      });

      socket.on('payment_status_update', (data) => {
        if (data.order_id == this.$route.query.id_order) {
          if (data.status != 'Payment Pending') {
            if (data.status == 'Payment Accepted') {
              if (true) {
                this.redirectPayment(data.reference_code);
              } else {
                this.$router.push({ path: '/vmcola/order/payment-success-failed' });
              }
              socket.off('payment_status_update');
            } else if (data.status == 'Payment Failure' || data.status == 'Cancelled') {
              this.$router.push({ path: '/vmcola/order/payment-failed' });
              socket.off('payment_status_update');
            }
          } else {
            if (this.order_detail) {
              if (
                this.order_detail.soco_state != 'Awaiting Veritrans payment' &&
                this.order_detail.soco_state != 'Waiting payment' &&
                (this.order_detail.soco_state == 'Delivered' || this.order_detail.soco_state == 'Payment accepted')
              ) {
                this.redirectPayment(data.reference_code);
              }
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'payment';
</style>
